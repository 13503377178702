import React, { useState } from 'react';
import { useBooking } from '../../context/BookingContext';

function BookingSummary({ selectedServices, totalDuration, totalPrice, onContinue, isBookingSystemEnabled, isSuperAdmin }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const canBook = isBookingSystemEnabled || isSuperAdmin;
  const { toggleService } = useBooking();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 sm:py-4">
        <div className="flex flex-col">
          {/* Summary Toggle */}
          <button 
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center justify-center text-sm text-gray-500 hover:text-gray-700 py-2 sm:py-1 touch-manipulation"
            aria-expanded={isExpanded}
            aria-label={isExpanded ? "Hide booking details" : "Show booking details"}
          >
            {isExpanded ? (
              <>
                <span>Hide details</span>
                <svg className="w-5 h-5 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              </>
            ) : (
              <>
                <span>Show details</span>
                <svg className="w-5 h-5 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </>
            )}
          </button>

          {/* Expanded Details */}
          {isExpanded && (
            <div className="mb-4 space-y-3 px-1">
              {selectedServices.map((service, index) => (
                <div key={index} className="flex justify-between text-sm items-center">
                  <div className="pr-4 flex-1">
                    <div className="flex flex-wrap items-baseline">
                      <span className="text-gray-900">{service.title}</span>
                      {service.selectedVariation && (
                        <span className="text-gray-500 ml-1 text-xs sm:text-sm">
                          ({service.selectedVariation.name})
                        </span>
                      )}
                    </div>
                    <span className="text-gray-500 text-xs sm:text-sm block sm:inline sm:ml-2">
                      {service.bookingLength.hours}h {service.bookingLength.minutes}m
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-gray-900 whitespace-nowrap">£{service.price}</span>
                    <button
                      onClick={() => toggleService(service)}
                      className="text-red-500 hover:text-red-700 p-1"
                      aria-label={`Remove ${service.title}`}
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
              <div className="border-t pt-3 mt-3">
                <div className="flex justify-between text-sm font-medium">
                  <span className="text-gray-500">Total Duration</span>
                  <span className="text-gray-900">{totalDuration.hours}h {totalDuration.minutes}m</span>
                </div>
              </div>
            </div>
          )}

          {/* Bottom Bar */}
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-3 sm:space-y-0 py-2">
            <div className="flex justify-between sm:block items-center">
              <div>
                <p className="text-gray-900 text-sm">
                  {selectedServices.length} service{selectedServices.length !== 1 ? 's' : ''} selected
                </p>
                <p className="text-sm text-gray-500">
                  {totalDuration.hours}h {totalDuration.minutes}m
                </p>
              </div>
              <p className="text-lg font-medium sm:hidden">
                £{totalPrice}
              </p>
            </div>
            <div className="flex items-center justify-between sm:justify-end sm:space-x-6">
              <p className="text-lg font-medium hidden sm:block">
                £{totalPrice}
              </p>
              {canBook && (
                <button
                  onClick={onContinue}
                  className="w-full sm:w-auto bg-black text-white px-8 py-3 sm:py-2.5 rounded-lg hover:bg-black/90 active:bg-black/80 transition-colors text-base font-medium touch-manipulation"
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Safe area spacing for mobile devices */}
      <div className="h-[env(safe-area-inset-bottom)] bg-white" />
    </div>
  );
}

export default BookingSummary;
