import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { sendBookingConfirmation, sendEmailWithRetry } from '../../../utils/emailService';
import { updateUserLoyalty, checkLoyaltyEligibility } from '../../../services/loyaltyService';
import BookingStatusSection from './components/BookingStatusSection';
import BookingServicesSection from './components/BookingServicesSection';
import BookingNotesSection from './components/BookingNotesSection';
import BookingDateTimeSection from './components/BookingDateTimeSection';

const BookingDetails = ({ booking, onClose, onBookingUpdate }) => {
  const [notes, setNotes] = useState('');
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState('');
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [isEditingDateTime, setIsEditingDateTime] = useState(false);
  const [statusError, setStatusError] = useState('');

  useEffect(() => {
    if (booking) {
      console.log('Initializing booking details:', {
        id: booking.id,
        status: booking.status,
        isImported: !booking.userId
      });
      setNotes(booking.adminNotes ?? '');
      setStatus(booking.status || '');
    }
  }, [booking]);

  const handleStatusChange = async (newStatus) => {
    if (!booking?.id) {
      console.error('Invalid booking data');
      setStatusError('Invalid booking data');
      return;
    }

    if (status === newStatus) {
      console.log('Status unchanged');
      return;
    }

    try {
      setUpdatingStatus(true);
      setStatusError('');
      console.log('Starting status update process:', { from: status, to: newStatus });

      const bookingRef = doc(db, 'bookings', booking.id);
      const updatedAt = new Date();
      
      await updateDoc(bookingRef, {
        status: newStatus,
        updatedAt
      });

      console.log('Successfully updated booking status in Firestore');

      const updatedBooking = {
        ...booking,
        status: newStatus,
        updatedAt
      };

      // Handle loyalty rewards when booking is completed
      if (newStatus === 'completed') {
        if (checkLoyaltyEligibility(booking)) {
          console.log('Processing loyalty update');
          try {
            await updateUserLoyalty(booking);
            console.log('Loyalty update completed successfully');
          } catch (error) {
            console.error('Loyalty update failed:', error);
            // Show warning but don't prevent status update
            setStatusError('Status updated but loyalty points could not be awarded');
          }
        } else {
          console.log('Booking not eligible for loyalty update');
        }
      }

      // Send confirmation email if needed
      if (newStatus === 'confirmed' && booking.userEmail) {
        console.log('Sending confirmation email');
        try {
          await sendEmailWithRetry(sendBookingConfirmation, {
            customerName: booking.userName || booking.userEmail?.split('@')[0] || 'Customer',
            date: booking.dateTime,
            serviceName: booking.services.map(service => {
              let name = service.title;
              if (service.selectedVariation) {
                name += ` (${service.selectedVariation.name})`;
              }
              return name;
            }).join(', '),
            totalAmount: booking.totalPrice,
            depositAmount: booking.depositAmount,
            bookingId: booking.id,
            customerEmail: booking.userEmail
          });
          console.log('Confirmation email sent successfully');
        } catch (error) {
          console.error('Failed to send confirmation email:', error);
          setStatusError('Status updated but confirmation email could not be sent');
        }
      }

      setStatus(newStatus);

      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }

      console.log('Status update completed successfully');

    } catch (error) {
      console.error('Status update failed:', error);
      setStatusError('Failed to update booking status. Please try again.');
      throw error; // Re-throw to be caught by the UI
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleSaveNotes = async () => {
    if (!booking?.id) {
      console.error('Invalid booking data');
      return;
    }
    
    try {
      setSaving(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      await updateDoc(bookingRef, {
        adminNotes: notes
      });
      
      const updatedBooking = {
        ...booking,
        adminNotes: notes
      };
      
      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleTimeSlotSelect = async (newDateTime, location) => {
    if (!booking?.id) {
      console.error('Invalid booking data');
      return;
    }

    try {
      setSaving(true);
      const bookingRef = doc(db, 'bookings', booking.id);
      
      await updateDoc(bookingRef, {
        dateTime: newDateTime,
        locationId: location.id,
        locationName: location.name,
        updatedAt: new Date()
      });

      const updatedBooking = {
        ...booking,
        dateTime: newDateTime,
        locationId: location.id,
        locationName: location.name,
        updatedAt: new Date()
      };

      if (onBookingUpdate) {
        onBookingUpdate(updatedBooking);
      }

      setIsEditingDateTime(false);
    } catch (error) {
      console.error('Error updating booking date/time:', error);
    } finally {
      setSaving(false);
    }
  };

  if (!booking) return null;

  const isImported = !booking.userId;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start sm:items-center justify-center z-50 p-0 sm:p-4">
      <div className="bg-white w-full sm:rounded-lg sm:max-w-2xl h-full sm:h-auto overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-4 py-3 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-base sm:text-lg font-medium text-gray-900">
              Booking Details
            </h3>
            {isImported && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 mt-1">
                Imported Booking
              </span>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500"
            aria-label="Close"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          <BookingStatusSection
            status={status}
            onStatusChange={handleStatusChange}
            isUpdating={updatingStatus}
            error={statusError}
            isImported={isImported}
          />

          <BookingDateTimeSection
            booking={booking}
            isEditing={isEditingDateTime}
            onEdit={() => setIsEditingDateTime(true)}
            onCancel={() => setIsEditingDateTime(false)}
            onTimeSlotSelect={handleTimeSlotSelect}
          />

          <BookingServicesSection booking={booking} />

          <BookingNotesSection
            notes={notes}
            onNotesChange={setNotes}
            onSave={handleSaveNotes}
            isSaving={saving}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
