import React from 'react';
import dayjs from 'dayjs';

const BookingServicesSection = ({ booking }) => {
  const isImported = !booking.userId;
  const isFullPayment = booking.services?.every(service => 
    service.depositPercentage === 100 || service.requireFullPayment
  );

  return (
    <div>
      <p className="text-sm font-medium text-gray-500 mb-2">Services</p>
      <div className="bg-gray-50 rounded-lg p-3 sm:p-4">
        <div className="space-y-3">
          {booking.services?.map((service, index) => (
            <div key={index} className="pb-2 border-b border-gray-200 last:border-0 last:pb-0">
              <div className="flex justify-between text-sm">
                <div className="pr-2">
                  <span className="text-gray-900">{service.title}</span>
                  {service.selectedVariation && (
                    <span className="text-gray-500 ml-1">
                      ({service.selectedVariation.name})
                    </span>
                  )}
                </div>
                <span className="text-gray-600 whitespace-nowrap">£{service.price}</span>
              </div>
              <div className="text-xs text-gray-500 mt-1">
                {isImported ? (
                  <a 
                    href="https://partners.fresha.com" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-primary hover:text-primary-dark"
                  >
                    Check Fresha
                  </a>
                ) : (
                  <>
                    {service.requireFullPayment ? 'Full payment required' :
                     service.depositPercentage ? `${service.depositPercentage}% deposit required` :
                     'Default deposit rate'}
                  </>
                )}
                {service.selectedVariation?.description && !isImported && (
                  <span className="block sm:inline sm:ml-2">• {service.selectedVariation.description}</span>
                )}
                {service.bookingLength && (
                  <span className="block sm:inline sm:ml-2">• Duration: {service.bookingLength.hours}h {service.bookingLength.minutes}m</span>
                )}
              </div>
            </div>
          ))}
          <div className="pt-2 border-t border-gray-200 flex justify-between text-sm font-medium">
            <span className="text-gray-900">Total Price</span>
            <span className="text-gray-900">£{booking.totalPrice}</span>
          </div>
          {!isImported && booking.payment && (
            <div className="pt-2 border-t border-gray-200 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-900">
                  {isFullPayment ? 'Payment Amount' : 'Required Deposit'}
                </span>
                <span className="text-gray-900">£{(booking.payment.amount).toFixed(2)}</span>
              </div>
              {!isFullPayment && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-900">Remaining Balance</span>
                  <span className="text-gray-900">£{(booking.totalPrice - booking.payment.amount).toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between text-sm">
                <span className="text-gray-900">Payment Status</span>
                <span className={`font-medium ${booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'}`}>
                  {booking.payment.status === 'completed' ? 'Paid' : 'Pending'}
                </span>
              </div>
              {booking.payment.status === 'completed' && booking.payment.created && (
                <div className="flex justify-between text-sm">
                  <span className="text-gray-900">Paid On</span>
                  <span className="text-gray-600">
                    {dayjs(booking.payment.created.toDate()).format('MMM D, YYYY HH:mm')}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingServicesSection;
