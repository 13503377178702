import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ManageServices from '../components/Admin/ManageServices';
import ViewBookings from '../components/Admin/ViewBookings';
import BookingSettings from '../components/Admin/BookingSettings';
import SuperAdminSettings from '../components/Admin/SuperAdminSettings';
import EmailTest from '../components/EmailTemplates/EmailTest';
import ManageUsers from '../components/Admin/ManageUsers';
import ManageLocations from '../components/Admin/ManageLocations';
import AddCategory from '../components/Admin/AddCategory';
import LoyaltySettings from '../components/Admin/LoyaltySettings';
import { useAuth } from '../context/AuthContext';
import { useBookingSystem } from '../context/BookingSystemContext';

const Admin = () => {
  const [activeTab, setActiveTab] = useState('bookings');
  const { isSuperAdmin } = useAuth();
  const { getBookingUrl } = useBookingSystem();
  const debugEmailsEnabled = process.env.REACT_APP_DEBUG_EMAILS === 'true';

  const bookingUrl = getBookingUrl();
  const isExternalBooking = bookingUrl.startsWith('http');

  useEffect(() => {
    console.log('Current active tab:', activeTab);
    // If external booking is enabled and current tab is bookings, switch to services
    if (isExternalBooking && activeTab === 'bookings') {
      setActiveTab('services');
    }
  }, [activeTab, isExternalBooking]);

  const handleTabClick = (tabName) => {
    console.log('Switching to tab:', tabName);
    setActiveTab(tabName);
  };

  const renderContent = () => {
    console.log('Rendering content for tab:', activeTab);
    switch (activeTab) {
      case 'bookings':
        return <ViewBookings />;
      case 'services':
        return <ManageServices />;
      case 'categories':
        return <AddCategory />;
      case 'locations':
        return <ManageLocations />;
      case 'users':
        return <ManageUsers />;
      case 'bookingSettings':
        return <BookingSettings />;
      case 'loyalty':
        return <LoyaltySettings />;
      case 'superAdmin':
        return <SuperAdminSettings />;
      default:
        return <ViewBookings />;
    }
  };

  const baseTabs = [
    { id: 'services', label: 'Manage Services' },
    { id: 'categories', label: 'Manage Categories' },
    { id: 'locations', label: 'Manage Locations' },
    { id: 'users', label: 'Manage Users' },
    { id: 'bookingSettings', label: 'Booking Settings' },
    { id: 'loyalty', label: 'Loyalty Settings' },
  ];

  // Only show bookings tab if not using external booking system
  const tabs = !isExternalBooking 
    ? [{ id: 'bookings', label: 'View Bookings' }, ...baseTabs]
    : baseTabs;

  if (isSuperAdmin) {
    tabs.push({ id: 'superAdmin', label: 'SuperAdmin' });
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-semibold mb-4 md:mb-0">Admin Dashboard</h1>
        <Link
          to="/"
          className="text-primary hover:text-primary-dark transition-colors"
        >
          Return to Website
        </Link>
      </div>

      <div className="mb-4 sm:mb-8 overflow-x-auto">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex flex-wrap gap-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`
                  whitespace-nowrap py-2 px-4 border-b-2 font-medium text-sm
                  ${activeTab === tab.id
                    ? 'border-primary text-primary font-bold'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                  ${tab.id === 'loyalty' ? 'bg-primary bg-opacity-10 rounded-t' : ''}
                `}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="overflow-x-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default Admin;
