import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Link } from 'react-router-dom';

function ServiceCategories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});

  // Preload images
  useEffect(() => {
    categories.forEach(category => {
      if (category.imageUrl) {
        const img = new Image();
        img.onload = () => {
          setLoadedImages(prev => ({
            ...prev,
            [category.id]: true
          }));
        };
        img.src = category.imageUrl;
      }
    });
  }, [categories]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        console.log('Fetching categories...');
        const categoriesQuery = query(collection(db, 'categories'), orderBy('order', 'asc'));
        const querySnapshot = await getDocs(categoriesQuery);
        const categoriesData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(category => !category.hidden);

        console.log('Categories fetched:', JSON.stringify(categoriesData, null, 2));
        // Log specific categories with missing images
        categoriesData.forEach(category => {
          if (!category.imageUrl) {
            console.warn(`Category "${category.name}" is missing imageUrl`);
          } else {
            console.log(`Category "${category.name}" has imageUrl:`, category.imageUrl);
          }
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return (
      <div className="w-full py-12 bg-primary">
        <div className="container mx-auto px-4">
          <div className="text-white text-center">Loading services...</div>
        </div>
      </div>
    );
  }

  if (categories.length === 0) {
    return (
      <div className="w-full py-12 bg-primary">
        <div className="container mx-auto px-4">
          <div className="text-white text-center">No services available.</div>
        </div>
      </div>
    );
  }

  return (
    <section className="w-full py-12 bg-primary">
      <div className="container mx-auto px-4 sm:px-6">
        <h2 className="text-3xl sm:text-4xl font-secondary text-white text-center mb-8">
          SERVICES
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map((category) => (
            <Link
              key={category.id}
              to={`/services/${encodeURIComponent(category.name)}`}
              className="block group relative h-[400px] rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div 
                className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                style={{
                  backgroundImage: loadedImages[category.id] ? `url("${category.imageUrl}")` : 'none',
                  backgroundColor: !loadedImages[category.id] ? '#8B6F5E' : 'transparent'
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-primary/70 to-primary/90 transition-opacity duration-300 group-hover:opacity-90" />
              <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
                <h3 className="text-xl sm:text-2xl font-secondary text-white tracking-wider mb-4">
                  {category.name.toUpperCase()}
                </h3>
                {category.shortDescription && (
                  <p className="text-white/90 font-primary text-sm sm:text-base leading-relaxed">
                    {category.shortDescription}
                  </p>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServiceCategories;
