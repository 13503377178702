import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider, useAuth } from './context/AuthContext';
import { MaintenanceProvider, useMaintenance } from './context/MaintenanceContext';
import { BookingProvider } from './context/BookingContext';
import { BookingSystemProvider, useBookingSystem } from './context/BookingSystemContext';
import NavigationBar from './components/Navigation/NavigationBar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './pages/Home';
import Services from './pages/Services';
import ServiceCategory from './pages/ServiceCategory';
import Booking from './pages/Booking';
import BookingConfirmation from './pages/BookingConfirmation';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Admin from './pages/Admin';
import Contact from './pages/Contact';
import MyAccount from './pages/MyAccount';
import MaintenancePage from './pages/MaintenancePage';
import NotFound from './pages/NotFound';
import { getStripeKey, stripeElementsOptions } from './config/stripe';
import './App.css';
import LoadingOverlay from './components/Common/LoadingOverlay';

// Initialize Stripe with error handling
const initStripe = () => {
  try {
    const key = getStripeKey();
    console.log('Initializing Stripe with key prefix:', key.substring(0, 7));
    return loadStripe(key);
  } catch (error) {
    console.error('Failed to initialize Stripe:', error);
    return null;
  }
};

const stripePromise = initStripe();

const MaintenanceWrapper = ({ children }) => {
  const { isMaintenanceMode } = useMaintenance();
  const { isSuperAdmin } = useAuth();
  
  if (isMaintenanceMode && !isSuperAdmin) {
    return <MaintenancePage />;
  }

  return children;
};

// Protected Route component for booking-related routes
const ProtectedBookingRoute = ({ children }) => {
  const { isBookingSystemEnabled } = useBookingSystem();
  const { isSuperAdmin } = useAuth();

  if (!isBookingSystemEnabled ) {
    return (
      <div className="min-h-screen bg-white pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Online Booking Temporarily Unavailable
            </h2>
            <p className="text-lg text-gray-600 mb-4">
              We're currently updating our booking system to serve you better.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              Please check back again soon. Thank you for your patience.
            </p>
            <div className="inline-block bg-gray-50 rounded-lg p-4">
              <p className="text-gray-600">
                Booking is not currently possible. Please try again later.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return children;
};

function AppContent() {
  const { isMaintenanceMode } = useMaintenance();
  const { isSuperAdmin } = useAuth();

  // Only show navigation when not in maintenance mode or for superadmin
  const showNavigation = !isMaintenanceMode || isSuperAdmin;

  return (
    <div className="flex flex-col min-h-screen">
      {showNavigation && <NavigationBar />}
      <main className="flex-grow">
        <ScrollToTop />
        <MaintenanceWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:category" element={<ServiceCategory />} />
            <Route 
              path="/booking" 
              element={
                <ProtectedBookingRoute>
                  <Booking />
                </ProtectedBookingRoute>
              } 
            />
            <Route 
              path="/booking-confirmation" 
              element={
                <ProtectedBookingRoute>
                  <BookingConfirmation />
                </ProtectedBookingRoute>
              } 
            />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MaintenanceWrapper>
      </main>
      {showNavigation && <Footer />}
    </div>
  );
}

function App() {
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {
    // Check if Stripe was initialized successfully
    stripePromise.then(stripe => {
      if (stripe) {
        setStripeLoaded(true);
      }
    });
  }, []);

  if (!stripeLoaded) {
    return <LoadingOverlay />;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <MaintenanceProvider>
          <BookingSystemProvider>
            <BookingProvider>
              <Elements stripe={stripePromise} options={stripeElementsOptions}>
                <AppContent />
              </Elements>
            </BookingProvider>
          </BookingSystemProvider>
        </MaintenanceProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
