import React, { useState } from 'react';
import { BOOKING_STATUSES, getStatusColor, getStatusBgColor } from '../utils/bookingStatus';

const BookingStatusSection = ({ status, onStatusChange, isUpdating, error }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleStatusSelect = (newStatus) => {
    console.log('Status option selected:', newStatus);
    onStatusChange(newStatus);
    setShowDropdown(false);
  };

  return (
    <div className="bg-gray-50 p-3 sm:p-4 rounded-lg">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-2 sm:space-y-0">
        <div>
          <p className="text-sm font-medium text-gray-500">Current Status</p>
          <p className={`mt-1 text-sm font-semibold ${getStatusColor(status)}`}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </p>
          {error && (
            <p className="mt-1 text-sm text-red-600">{error}</p>
          )}
        </div>
        <div className="relative">
          <button
            onClick={() => {
              console.log('Opening status dropdown');
              setShowDropdown(!showDropdown);
            }}
            className={`
              w-full sm:w-40 px-4 py-2 text-left text-sm font-medium rounded-md
              border border-gray-300 shadow-sm
              focus:outline-none focus:ring-2 focus:ring-primary
              ${getStatusBgColor(status)}
              ${isUpdating ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}
            `}
            disabled={isUpdating}
          >
            Change Status
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </span>
          </button>
          
          {showDropdown && (
            <div className="absolute right-0 mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              <div className="py-1" role="menu" aria-orientation="vertical">
                {BOOKING_STATUSES.map((statusOption) => (
                  <button
                    key={statusOption.value}
                    onClick={() => handleStatusSelect(statusOption.value)}
                    className={`
                      w-full text-left px-4 py-2 text-sm
                      ${status === statusOption.value ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}
                      hover:bg-gray-50 focus:outline-none focus:bg-gray-50
                    `}
                    role="menuitem"
                  >
                    {statusOption.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingStatusSection;
