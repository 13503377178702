import React from 'react';

const ExternalBookingSection = ({ externalBookingUrl, onExternalBookingUrlChange }) => {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">External Booking System</h3>
      <div className="space-y-4">
        <div>
          <label htmlFor="externalBookingUrl" className="block text-sm font-medium text-gray-700">
            External Booking URL
          </label>
          <div className="mt-1">
            <input
              type="url"
              id="externalBookingUrl"
              value={externalBookingUrl || ''}
              onChange={(e) => onExternalBookingUrlChange(e.target.value)}
              placeholder="https://your-booking-system.com"
              className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <p className="mt-2 text-sm text-gray-500">
            If you use an external booking system, enter its URL here. Leave empty to use the built-in booking system.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExternalBookingSection;
