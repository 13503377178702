import React from 'react';
import { Helmet } from 'react-helmet-async';
import HomeHero from '../components/HomeHero/HomeHero';
import SectionSideBySide from '../components/SectionTemplate/SectionSideBySide';
import ServiceCategories from '../components/ServiceCategories/ServiceCategories';
import welcomeImage from '../assets/images/HomeHeroImages/SCHero1.jpeg';

function Home() {
  const metaDescription = "Welcome to The Skin Chalet in Headley. With over 12 years of experience, we specialize in eyebrow treatments, semi-permanent makeup, facials, and waxing. Experience professional beauty services tailored to enhance your natural features.";
  const metaKeywords = "skin chalet, beauty salon Headley, eyebrow specialist, semi-permanent makeup, facials, waxing, beauty treatments, the brow lady, professional skincare, luxury beauty services";

  return (
    <>
      <Helmet>
        <title>Skin Chalet | Luxury Beauty & Skincare Treatments in Headley</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Skin Chalet | Luxury Beauty & Skincare Treatments" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skinchalet.co.uk" />
        <meta property="og:image" content={welcomeImage} />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Skin Chalet | Luxury Beauty & Skincare Treatments" />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={welcomeImage} />
        
        {/* Location Tags */}
        <meta name="geo.region" content="GB" />
        <meta name="geo.placename" content="Headley" />
        
        {/* Additional Tags */}
        <link rel="canonical" href="https://skinchalet.co.uk" />
        <meta name="author" content="Sam - The Skin Chalet" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <HomeHero />
      <SectionSideBySide 
        img={welcomeImage}
        heading="Welcome to The Skin Chalet"
        text="Hello, I'm Sam, the proud owner of The Skin Chalet. With over 12 years of experience in various beauty settings, I am dedicated to helping women enhance their natural features through a range of treatments. While I am known as 'The Brow Lady' due to my passion for eyebrows, my expertise extends to other beauty services such as semi-permanent makeup, facials, and waxing. Located in Headley, appointments at my clinic must be scheduled in advance. I'm excited to welcome you for a wonderful treatment experience."
      />
      <ServiceCategories />
    </>
  );
}

export default Home;
