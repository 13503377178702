import React from 'react';

const BookingNotesSection = ({ notes, onNotesChange, onSave, isSaving }) => {
  return (
    <div>
      <p className="text-sm font-medium text-gray-500 mb-2">Notes</p>
      <div className="mt-1">
        <textarea
          rows={4}
          className="shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md"
          value={notes}
          onChange={(e) => onNotesChange(e.target.value)}
          placeholder="Add notes about this booking..."
        />
      </div>
      <div className="mt-2 flex justify-end">
        <button
          onClick={onSave}
          disabled={isSaving}
          className={`
            w-full sm:w-auto inline-flex items-center justify-center 
            px-4 py-2 border border-transparent text-sm font-medium 
            rounded-md shadow-sm text-white bg-primary 
            hover:bg-opacity-90 focus:outline-none focus:ring-2 
            focus:ring-offset-2 focus:ring-primary
            ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}
          `}
        >
          {isSaving ? 'Saving...' : 'Save Notes'}
        </button>
      </div>
    </div>
  );
};

export default BookingNotesSection;
