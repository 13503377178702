export const BOOKING_STATUSES = [
  { value: 'pending', label: 'Pending' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'rejected', label: 'Rejected' }
];

export const getStatusColor = (status) => {
  switch (status) {
    case 'confirmed': return 'text-green-600';
    case 'pending': return 'text-yellow-600';
    case 'rejected': return 'text-red-600';
    case 'completed': return 'text-blue-600';
    case 'cancelled': return 'text-gray-600';
    default: return 'text-gray-600';
  }
};

export const getStatusBgColor = (status) => {
  switch (status) {
    case 'confirmed': return 'bg-green-50';
    case 'pending': return 'bg-yellow-50';
    case 'rejected': return 'bg-red-50';
    case 'completed': return 'bg-blue-50';
    case 'cancelled': return 'bg-gray-50';
    default: return 'bg-gray-50';
  }
};
