import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const BookingSystemContext = createContext();

export const useBookingSystem = () => {
  const context = useContext(BookingSystemContext);
  if (!context) {
    throw new Error('useBookingSystem must be used within a BookingSystemProvider');
  }
  return context;
};

export const BookingSystemProvider = ({ children }) => {
  const [isBookingSystemEnabled, setIsBookingSystemEnabled] = useState(false);
  const [externalBookingUrl, setExternalBookingUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookingSystemStatus = async () => {
      try {
        console.log('Fetching booking system status...');
        const systemRef = doc(db, 'settings', 'bookingSystem');
        const bookingRef = doc(db, 'settings', 'booking');
        
        const [systemSnap, bookingSnap] = await Promise.all([
          getDoc(systemRef),
          getDoc(bookingRef)
        ]);
        
        if (systemSnap.exists()) {
          const enabled = systemSnap.data().enabled;
          console.log('Booking system status fetched:', enabled);
          setIsBookingSystemEnabled(enabled);
        } else {
          console.log('No booking system settings found, initializing with default (disabled)');
          await setDoc(systemRef, { enabled: false });
          setIsBookingSystemEnabled(false);
        }

        if (bookingSnap.exists()) {
          const { externalBookingUrl } = bookingSnap.data();
          setExternalBookingUrl(externalBookingUrl || '');
        }
      } catch (error) {
        console.error('Error fetching booking system status:', error);
        setIsBookingSystemEnabled(false);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingSystemStatus();
  }, []);

  const toggleBookingSystem = async (enabled) => {
    try {
      console.log('Toggling booking system to:', enabled);
      const docRef = doc(db, 'settings', 'bookingSystem');
      
      await setDoc(docRef, { enabled });
      
      const updatedDoc = await getDoc(docRef);
      if (updatedDoc.exists() && updatedDoc.data().enabled === enabled) {
        console.log('Booking system successfully toggled to:', enabled);
        setIsBookingSystemEnabled(enabled);
      } else {
        console.error('Booking system update failed - Firestore state mismatch');
        throw new Error('Failed to update booking system state');
      }
    } catch (error) {
      console.error('Error toggling booking system:', error);
      const docRef = doc(db, 'settings', 'bookingSystem');
      const currentDoc = await getDoc(docRef);
      if (currentDoc.exists()) {
        setIsBookingSystemEnabled(currentDoc.data().enabled);
      } else {
        setIsBookingSystemEnabled(false);
      }
      throw error;
    }
  };

  const getBookingUrl = () => {
    return externalBookingUrl || '/booking';
  };

  if (loading) {
    return null;
  }

  return (
    <BookingSystemContext.Provider value={{ 
      isBookingSystemEnabled, 
      toggleBookingSystem,
      externalBookingUrl,
      getBookingUrl,
      loading 
    }}>
      {children}
    </BookingSystemContext.Provider>
  );
};
