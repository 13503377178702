import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

const LoyaltySettings = () => {
  const [settings, setSettings] = useState({
    enabled: false,
    type: 'points',
    pointsConfig: {
      poundsPerPoint: 1
    },
    stampsConfig: {
      bookingValueForStamp: 20
    }
  });

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const docRef = doc(db, 'settings', 'loyalty');
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log('Fetched settings:', docSnap.data());
          setSettings(docSnap.data());
        }
      } catch (error) {
        console.error('Error fetching loyalty settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSave = async () => {
    try {
      setSaving(true);
      console.log('Saving settings:', settings);
      await setDoc(doc(db, 'settings', 'loyalty'), settings);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving loyalty settings:', error);
      alert('Error saving settings. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleToggle = () => {
    console.log('Toggling enabled state from', settings.enabled, 'to', !settings.enabled);
    setSettings(prev => ({
      ...prev,
      enabled: !prev.enabled
    }));
  };

  const handleTypeChange = (e) => {
    setSettings(prev => ({
      ...prev,
      type: e.target.value
    }));
  };

  const handleValueChange = (value, field) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue) || numValue < 0) return;

    if (settings.type === 'points') {
      setSettings(prev => ({
        ...prev,
        pointsConfig: {
          ...prev.pointsConfig,
          [field]: numValue
        }
      }));
    } else {
      setSettings(prev => ({
        ...prev,
        stampsConfig: {
          ...prev.stampsConfig,
          [field]: numValue
        }
      }));
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-gray-500">Loading settings...</div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-2xl font-semibold mb-6">Loyalty Program Settings</h2>
        
        <div className="space-y-6">
          {/* Enable/Disable Toggle */}
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <label className="font-medium text-gray-700">Enable Loyalty Program</label>
              <p className="text-sm text-gray-500 mt-1">
                Turn the loyalty program on or off
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={settings.enabled}
                onChange={handleToggle}
              />
              <div className={`
                w-14 h-7 rounded-full peer 
                peer-focus:ring-4 peer-focus:ring-primary/20
                after:content-[''] after:absolute after:top-0.5 after:left-[4px] 
                after:bg-white after:rounded-full after:h-6 after:w-6 after:transition-all
                peer-checked:after:translate-x-7
                ${settings.enabled ? 'bg-primary' : 'bg-gray-200'}
              `}></div>
            </label>
          </div>

          {settings.enabled && (
            <>
              {/* Loyalty Type Selection */}
              <div className="p-4 bg-gray-50 rounded-lg">
                <label className="block font-medium text-gray-700 mb-1">Loyalty Type</label>
                <p className="text-sm text-gray-500 mb-3">
                  Choose between points-based or stamps-based loyalty system
                </p>
                <select
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  value={settings.type}
                  onChange={handleTypeChange}
                >
                  <option value="points">Points Based</option>
                  <option value="stamps">Stamps Based</option>
                </select>
              </div>

              {/* Conditional Fields Based on Type */}
              <div className="p-4 bg-gray-50 rounded-lg">
                {settings.type === 'points' ? (
                  <div>
                    <label className="block font-medium text-gray-700 mb-1">
                      Pounds spent per point (£)
                    </label>
                    <p className="text-sm text-gray-500 mb-3">
                      How many pounds must be spent to earn one point
                    </p>
                    <input
                      type="number"
                      className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      value={settings.pointsConfig.poundsPerPoint}
                      onChange={(e) => handleValueChange(e.target.value, 'poundsPerPoint')}
                      min="0.01"
                      step="0.01"
                    />
                  </div>
                ) : (
                  <div>
                    <label className="block font-medium text-gray-700 mb-1">
                      Booking value required for stamp (£)
                    </label>
                    <p className="text-sm text-gray-500 mb-3">
                      Minimum booking value required to earn a stamp
                    </p>
                    <input
                      type="number"
                      className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      value={settings.stampsConfig.bookingValueForStamp}
                      onChange={(e) => handleValueChange(e.target.value, 'bookingValueForStamp')}
                      min="0.01"
                      step="0.01"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {/* Save Button - Now always visible */}
          <button
            onClick={handleSave}
            disabled={saving}
            className={`
              w-full py-3 px-4 rounded-md text-white font-medium
              transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
              ${saving
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-primary hover:bg-primary-dark'
              }
            `}
          >
            {saving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoyaltySettings;
