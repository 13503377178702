import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Link } from 'react-router-dom';

const Services = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState({});

  // Preload images
  useEffect(() => {
    categories.forEach(category => {
      if (category.imageUrl) {
        const img = new Image();
        img.onload = () => {
          setLoadedImages(prev => ({
            ...prev,
            [category.id]: true
          }));
        };
        img.src = category.imageUrl;
      }
    });
  }, [categories]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesQuery = query(collection(db, 'categories'), orderBy('order', 'asc'));
        const categorySnapshot = await getDocs(categoriesQuery);
        const categoryList = categorySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(category => !category.hidden);
        setCategories(categoryList);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Generate dynamic meta description and keywords based on categories
  const generateMetaDescription = () => {
    if (categories.length === 0) return "Explore our comprehensive range of luxury skincare and beauty treatments at Skin Chalet.";
    
    const categoryNames = categories.map(cat => cat.name).join(', ');
    return `Discover our luxury skincare services including ${categoryNames}. Professional beauty treatments tailored to your needs at Skin Chalet.`;
  };

  const generateMetaKeywords = () => {
    const baseKeywords = ['skincare', 'beauty treatments', 'luxury spa', 'facial treatments'];
    const categoryKeywords = categories.flatMap(cat => [
      cat.name.toLowerCase(),
      `${cat.name.toLowerCase()} treatment`,
      `luxury ${cat.name.toLowerCase()}`
    ]);
    return [...new Set([...baseKeywords, ...categoryKeywords])].join(', ');
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Our Services | Skin Chalet - Luxury Skincare & Beauty Treatments</title>
        <meta name="description" content={generateMetaDescription()} />
        <meta name="keywords" content={generateMetaKeywords()} />
        <meta property="og:title" content="Our Services | Skin Chalet - Luxury Skincare & Beauty Treatments" />
        <meta property="og:description" content={generateMetaDescription()} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skinchalet.co.uk/services" />
        <link rel="canonical" href="https://skinchalet.co.uk/services" />
        <meta name="twitter:title" content="Our Services | Skin Chalet" />
        <meta name="twitter:description" content={generateMetaDescription()} />
      </Helmet>

      <div className="min-h-screen bg-white">
        {/* Page Header */}
        <div className="relative h-[30vh] w-full mt-24">
          <div className="absolute inset-0 bg-primary"></div>
          <div className="absolute inset-0 flex items-center justify-center px-4">
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-secondary text-white tracking-[.25em] sm:tracking-[.5em] text-center">
              SERVICES
            </h1>
          </div>
        </div>

        {/* Categories Grid */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/services/${encodeURIComponent(category.name)}`}
                className="block group relative aspect-w-3 aspect-h-4 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div 
                  className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                  style={{
                    backgroundImage: loadedImages[category.id] ? `url("${category.imageUrl}")` : 'none',
                    backgroundColor: !loadedImages[category.id] ? '#8B6F5E' : 'transparent'
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-b from-primary/70 to-primary/90 transition-opacity duration-300 group-hover:opacity-90" />
                <div className="absolute inset-0 flex flex-col items-center justify-center p-6 text-center">
                  <h2 className="text-xl sm:text-2xl font-secondary text-white tracking-wider mb-4">
                    {category.name.toUpperCase()}
                  </h2>
                  {category.shortDescription && (
                    <p className="text-white/90 font-primary text-sm sm:text-base leading-relaxed">
                      {category.shortDescription}
                    </p>
                  )}
                </div>
              </Link>
            ))}
          </div>

          {categories.length === 0 && (
            <div className="text-center text-gray-600">
              No services available at the moment.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
