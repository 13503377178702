import React, { useState, useMemo, useCallback, memo } from 'react';
import dayjs from 'dayjs';
import { BOOKING_STATUSES } from './utils/bookingStatus';

// Memoized FilterSection component
const FilterSection = memo(({ filters, onFilterChange }) => (
  <div className="bg-white p-4 border-b">
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      <div>
        <label htmlFor="customer-filter" className="block text-sm font-medium text-gray-700">
          Search Customer
        </label>
        <input
          type="text"
          id="customer-filter"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Search by email..."
          value={filters.customer}
          onChange={(e) => onFilterChange('customer', e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="status-filter" className="block text-sm font-medium text-gray-700">
          Status
        </label>
        <select
          id="status-filter"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={filters.status}
          onChange={(e) => onFilterChange('status', e.target.value)}
        >
          <option value="">All Statuses</option>
          {BOOKING_STATUSES.map(status => (
            <option key={status.value} value={status.value}>{status.label}</option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="service-filter" className="block text-sm font-medium text-gray-700">
          Service
        </label>
        <select
          id="service-filter"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={filters.service}
          onChange={(e) => onFilterChange('service', e.target.value)}
        >
          <option value="">All Services</option>
          {filters.uniqueServices.map(service => (
            <option key={service} value={service}>{service}</option>
          ))}
        </select>
      </div>
    </div>
  </div>
));

const ListView = ({ bookings, onBookingClick }) => {
  const [filters, setFilters] = useState({
    customer: '',
    status: '',
    service: '',
    uniqueServices: []
  });

  // Get unique list for service dropdown
  const uniqueServices = useMemo(() => {
    const services = new Set();
    bookings.forEach(booking => {
      booking.services.forEach(service => {
        services.add(service.title);
      });
    });
    return Array.from(services);
  }, [bookings]);

  // Update uniqueServices in filters when it changes
  React.useEffect(() => {
    setFilters(prev => ({ ...prev, uniqueServices }));
  }, [uniqueServices]);

  // Memoized filter change handler
  const handleFilterChange = useCallback((field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }));
  }, []);

  // Filter bookings based on selected filters
  const filteredBookings = useMemo(() => {
    return bookings.filter(booking => {
      const customerMatch = !filters.customer || 
        (booking.userEmail && booking.userEmail.toLowerCase().includes(filters.customer.toLowerCase()));
      const statusMatch = !filters.status || booking.status === filters.status;
      const serviceMatch = !filters.service || booking.services.some(s => s.title === filters.service);
      return customerMatch && statusMatch && serviceMatch;
    });
  }, [bookings, filters.customer, filters.status, filters.service]);

  // Mobile card view component
  const MobileBookingCard = ({ booking }) => (
    <div 
      className="bg-white p-4 rounded-lg shadow mb-4 cursor-pointer hover:bg-gray-50"
      onClick={() => onBookingClick(booking)}
    >
      <div className="flex justify-between items-start mb-3">
        <div className="text-sm font-medium">
          {dayjs(booking.dateTime).format('MMM D, YYYY')}
          <div className="text-xs text-gray-500">
            {dayjs(booking.dateTime).format('HH:mm')}
          </div>
        </div>
        <span className={`px-2 py-1 text-xs leading-4 font-semibold rounded-full ${
          booking.status === 'confirmed' ? 'bg-green-100 text-green-800' : 
          booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
          booking.status === 'cancelled' ? 'bg-gray-100 text-gray-800' :
          booking.status === 'rejected' ? 'bg-red-100 text-red-800' :
          'bg-gray-100 text-gray-800'
        }`}>
          {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
        </span>
      </div>

      <div className="space-y-2">
        <div>
          <div className="text-xs text-gray-500">Location</div>
          <div className="text-sm">{booking.locationName}</div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Customer</div>
          <div className="text-sm">
            {booking.userEmail || 'No email available'}
            {!booking.userId && (
              <span className="ml-2 text-xs text-yellow-600 font-medium">
                Imported
              </span>
            )}
          </div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Services</div>
          <div className="text-sm">
            {booking.services.map((service, index) => (
              <div key={index} className="flex items-center space-x-1">
                <span>{service.title}</span>
                {service.selectedVariation && (
                  <span className="text-gray-500 text-xs">
                    ({service.selectedVariation.name})
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="text-xs text-gray-500">Total</div>
          <div className="text-sm">
            £{booking.totalPrice}
            {booking.payment && (
              <span className={`ml-2 text-xs ${
                booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'
              }`}>
                {booking.payment.status === 'completed' ? 'Paid' : 'Payment Pending'}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  // Desktop table view
  const DesktopTable = () => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date/Time
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Location
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Customer
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Services
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Total
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {filteredBookings.map((booking) => (
          <tr key={booking.id} className="hover:bg-gray-50 cursor-pointer"
              onClick={() => onBookingClick(booking)}>
            <td className="px-6 py-4 whitespace-nowrap">
              {dayjs(booking.dateTime).format('MMM D, YYYY HH:mm')}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {booking.locationName}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex flex-col">
                <span>{booking.userEmail || 'No email available'}</span>
                {!booking.userId && (
                  <span className="text-xs text-yellow-600 font-medium">
                    Imported Booking
                  </span>
                )}
              </div>
            </td>
            <td className="px-6 py-4">
              <div className="text-sm">
                {booking.services.map((service, index) => (
                  <div key={index} className="flex items-center space-x-1">
                    <span>{service.title}</span>
                    {service.selectedVariation && (
                      <span className="text-gray-500 text-xs">
                        ({service.selectedVariation.name})
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex flex-col">
                <span>£{booking.totalPrice}</span>
                {booking.payment && (
                  <span className={`text-xs ${
                    booking.payment.status === 'completed' ? 'text-green-600' : 'text-yellow-600'
                  }`}>
                    {booking.payment.status === 'completed' ? 'Paid' : 'Payment Pending'}
                  </span>
                )}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                booking.status === 'confirmed' ? 'bg-green-100 text-green-800' : 
                booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                booking.status === 'cancelled' ? 'bg-gray-100 text-gray-800' :
                booking.status === 'rejected' ? 'bg-red-100 text-red-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="bg-gray-100">
      <FilterSection filters={filters} onFilterChange={handleFilterChange} />
      
      <div className="bg-gray-100 sm:bg-white sm:shadow sm:overflow-hidden sm:rounded-lg">
        {/* Mobile View */}
        <div className="sm:hidden px-4 py-4 space-y-4">
          {filteredBookings.map((booking) => (
            <MobileBookingCard key={booking.id} booking={booking} />
          ))}
        </div>

        {/* Desktop View */}
        <div className="hidden sm:block">
          <DesktopTable />
        </div>
      </div>
    </div>
  );
};

export default ListView;
