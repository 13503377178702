import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { useBookingSystem } from '../context/BookingSystemContext';
import dayjs from 'dayjs';

const MyAccount = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loyaltyData, setLoyaltyData] = useState(null);
  const [loyaltySettings, setLoyaltySettings] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const { user } = useAuth();
  const { externalBookingUrl } = useBookingSystem();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to signin if not authenticated
    if (!user) {
      navigate('/signin');
      return;
    }

    const fetchData = async () => {
      try {
        // Only fetch bookings if there's no external booking system
        if (!externalBookingUrl) {
          const bookingsQuery = query(
            collection(db, 'bookings'),
            where('userId', '==', user.uid)
          );
          const bookingsSnapshot = await getDocs(bookingsQuery);
          const bookingsData = bookingsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          const sortedBookings = bookingsData.sort((a, b) => {
            const dateA = a.dateTime?.toDate?.() || new Date(a.dateTime);
            const dateB = b.dateTime?.toDate?.() || new Date(b.dateTime);
            return dateB.getTime() - dateA.getTime();
          });
          setBookings(sortedBookings);
        }

        // Fetch user data including loyalty
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setLoyaltyData(userData.loyalty || { points: 0, stamps: 0 });
          setPhoneNumber(userData.phoneNumber || '');
        }

        // Fetch loyalty settings
        const loyaltySettingsDoc = await getDoc(doc(db, 'settings', 'loyalty'));
        if (loyaltySettingsDoc.exists()) {
          setLoyaltySettings(loyaltySettingsDoc.data());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load account data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, navigate, externalBookingUrl]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleUpdatePhone = async () => {
    try {
      await updateDoc(doc(db, 'users', user.uid), {
        phoneNumber: phoneNumber
      });
      setIsEditingPhone(false);
    } catch (error) {
      console.error('Error updating phone number:', error);
      setError('Failed to update phone number');
    }
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDateTime = (dateTime) => {
    if (!dateTime) return '';
    const date = dateTime?.toDate?.() || new Date(dateTime);
    return dayjs(date).format('MMMM D, YYYY [at] h:mm A');
  };

  // Show loading state while checking authentication or fetching data
  if (!user || loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white pt-32">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white pt-32">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Account Header */}
        <div className="text-center mb-8 sm:mb-12">
          <h1 className="text-2xl sm:text-3xl font-secondary text-primary tracking-wide mb-2">
            My Account
          </h1>
          <p className="text-gray-600 font-primary">
            Welcome back, {user.displayName || user.email.split('@')[0]}
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
            <p className="text-red-600 text-sm font-body text-center">{error}</p>
          </div>
        )}

        {/* Loyalty Section */}
        {loyaltySettings?.enabled && (
          <div className="mb-8 bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
            <h2 className="text-xl font-secondary text-primary mb-4">My Loyalty Rewards</h2>
            <div className="flex items-center justify-center space-x-4">
              {loyaltySettings.type === 'points' ? (
                <div className="text-center">
                  <div className="text-3xl font-secondary text-primary">{loyaltyData?.points || 0}</div>
                  <div className="text-sm text-gray-600">Points Earned</div>
                </div>
              ) : (
                <div className="text-center">
                  <div className="text-3xl font-secondary text-primary">{loyaltyData?.stamps || 0}</div>
                  <div className="text-sm text-gray-600">Stamps Collected</div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Phone Number Section */}
        <div className="mb-8 bg-white border border-gray-200 rounded-lg p-6 shadow-sm">
          <h2 className="text-xl font-secondary text-primary mb-4">Contact Information</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
              {isEditingPhone ? (
                <div className="flex space-x-2">
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="flex-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                    placeholder="Enter phone number"
                  />
                  <button
                    onClick={handleUpdatePhone}
                    className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setIsEditingPhone(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <span className="text-gray-900">{phoneNumber || 'No phone number added'}</span>
                  <button
                    onClick={() => setIsEditingPhone(true)}
                    className="text-primary hover:text-primary-dark"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bookings Section - Only show if no external booking URL */}
        {!externalBookingUrl && (
          <div className="mb-8">
            <h2 className="text-xl font-secondary text-primary mb-6">My Bookings</h2>
            
            {bookings.length === 0 ? (
              <div className="text-center py-8 bg-gray-50 rounded-lg">
                <p className="text-gray-600 font-primary">No bookings found</p>
              </div>
            ) : (
              <div className="space-y-6">
                {bookings.map((booking) => (
                  <div
                    key={booking.id}
                    className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm"
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                      <div>
                        <div className="flex items-center gap-3 mb-2">
                          <h3 className="font-secondary text-lg">
                            {booking.services.map(service => service.title).join(', ')}
                          </h3>
                          <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(booking.status)}`}>
                            {booking.status}
                          </span>
                        </div>
                        <p className="text-gray-600 font-primary">
                          {formatDateTime(booking.dateTime)}
                        </p>
                        {booking.locationName && (
                          <p className="text-gray-600 font-primary mt-1">
                            Location: {booking.locationName}
                          </p>
                        )}
                      </div>
                      <div className="text-right">
                        <div className="text-lg font-secondary text-primary">
                          £{booking.totalPrice}
                        </div>
                        {booking.depositAmount && (
                          <div className="text-sm text-gray-500">
                            Deposit: £{booking.depositAmount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Account Actions */}
        <div className="border-t border-gray-200 pt-8 pb-16">
          <button
            onClick={handleSignOut}
            className="w-full sm:w-auto px-8 py-3 bg-transparent border-2 border-primary text-primary hover:bg-primary hover:text-white transition-colors duration-200 rounded-lg text-sm font-medium tracking-wide"
          >
            SIGN OUT
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
