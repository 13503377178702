import { doc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from '../config/firebase';

export const updateUserLoyalty = async (booking) => {
  const { userId, totalPrice, id: bookingId } = booking;

  if (!userId || !totalPrice) {
    console.log('Skipping loyalty update - invalid booking data:', { userId, totalPrice });
    return;
  }

  console.log('Starting loyalty update process:', { userId, totalPrice });

  try {
    const loyaltySettingsRef = doc(db, 'settings', 'loyalty');
    const loyaltySettingsDoc = await getDoc(loyaltySettingsRef);
    
    if (!loyaltySettingsDoc.exists()) {
      console.log('No loyalty settings found');
      return;
    }

    const settings = loyaltySettingsDoc.data();
    if (!settings.enabled) {
      console.log('Loyalty program is disabled');
      return;
    }

    console.log('Found loyalty settings:', settings);

    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', userId);
      const userDoc = await transaction.get(userRef);
      
      if (!userDoc.exists()) {
        console.log('User document not found:', userId);
        throw new Error('User document not found');
      }

      const userData = userDoc.data();
      const loyalty = userData.loyalty || {
        points: 0,
        stamps: 0,
        history: []
      };

      let rewardEarned = 0;
      if (settings.type === 'points') {
        const pointsEarned = Math.floor(totalPrice / settings.pointsConfig.poundsPerPoint);
        loyalty.points = (loyalty.points || 0) + pointsEarned;
        rewardEarned = pointsEarned;
        console.log(`Added ${pointsEarned} points to user ${userId}`);
      } else {
        if (totalPrice >= settings.stampsConfig.bookingValueForStamp) {
          loyalty.stamps = (loyalty.stamps || 0) + 1;
          rewardEarned = 1;
          console.log(`Added 1 stamp to user ${userId}`);
        }
      }

      loyalty.history = loyalty.history || [];
      loyalty.history.push({
        bookingId,
        amount: totalPrice,
        type: settings.type,
        earned: rewardEarned,
        date: new Date()
      });

      transaction.update(userRef, { loyalty });
      console.log('Successfully updated loyalty for user:', userId);
    });

    return true;
  } catch (error) {
    console.error('Error in updateUserLoyalty:', error);
    throw new Error(`Failed to update loyalty: ${error.message}`);
  }
};

export const checkLoyaltyEligibility = (booking) => {
  if (!booking) {
    console.log('Invalid booking object');
    return false;
  }

  if (!booking.userId) {
    console.log('Skipping loyalty update - imported booking');
    return false;
  }
  
  if (!booking.totalPrice) {
    console.log('Skipping loyalty update - no total price');
    return false;
  }

  if (booking.totalPrice <= 0) {
    console.log('Skipping loyalty update - invalid price');
    return false;
  }

  return true;
};
