import React from 'react';
import dayjs from 'dayjs';
import DateTimeSelection from '../../../Booking/DateTimeSelection';

const BookingDateTimeSection = ({ 
  booking, 
  isEditing, 
  onEdit, 
  onCancel, 
  onTimeSlotSelect 
}) => {
  const totalDuration = booking.totalDuration || { hours: 0, minutes: 0 };

  if (isEditing) {
    return (
      <div className="bg-white rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-base sm:text-lg font-medium">Select New Date & Time</h4>
          <button
            onClick={onCancel}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            Cancel
          </button>
        </div>
        <DateTimeSelection
          selectedServices={booking.services}
          onTimeSlotSelect={onTimeSlotSelect}
        />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div>
        <p className="text-sm font-medium text-gray-500">Date</p>
        <div className="flex items-center space-x-2 mt-1">
          <p className="text-sm text-gray-900">{dayjs(booking.dateTime).format('MMMM D, YYYY')}</p>
          <button
            onClick={onEdit}
            className="text-primary hover:text-primary-dark text-sm p-1"
          >
            Edit
          </button>
        </div>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">Time</p>
        <p className="text-sm text-gray-900 mt-1">{dayjs(booking.dateTime).format('HH:mm')}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">Location</p>
        <p className="text-sm text-gray-900 mt-1">{booking.locationName}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">Customer</p>
        <p className="text-sm text-gray-900 mt-1 break-words">{booking.userEmail || 'No email available'}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">Total Duration</p>
        <p className="text-sm text-gray-900 mt-1">
          {totalDuration.hours}h {totalDuration.minutes}m
        </p>
      </div>
    </div>
  );
};

export default BookingDateTimeSection;
