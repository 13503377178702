import React from 'react';
import { useBookingSystem } from '../../context/BookingSystemContext';
import { useAuth } from '../../context/AuthContext';
import dayjs from 'dayjs';

const VariationModal = ({ isOpen, onClose, variations, onSelect, title }) => {
  const { isBookingSystemEnabled } = useBookingSystem();
  const { isSuperAdmin } = useAuth();

  const canBook = isBookingSystemEnabled || isSuperAdmin;

  if (!isOpen || !canBook) return null;

  const formatDuration = (variation) => {
    // Handle both data structures
    const hours = variation.bookingLength?.hours || variation.bookingLengthHours || 0;
    const minutes = variation.bookingLength?.minutes || variation.bookingLengthMinutes || 0;
    
    if (hours === 0 && minutes === 0) return '';
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-start sm:items-center justify-center p-4 sm:p-6">
      <div 
        className="bg-white rounded-xl shadow-xl w-full max-w-md max-h-[90vh] overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="px-4 sm:px-6 py-4 border-b">
          <h2 className="text-xl sm:text-2xl font-['Bodoni_Classico']">{title}</h2>
        </div>

        {/* Variations List */}
        <div className="px-4 sm:px-6 py-4 overflow-y-auto max-h-[60vh]">
          <div className="space-y-3">
            {variations.map((variation, index) => (
              <button
                key={index}
                onClick={() => onSelect(variation)}
                className="w-full text-left p-4 rounded-lg border border-gray-200 hover:bg-gray-50 active:bg-gray-100 transition-colors touch-manipulation"
              >
                <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
                  <div className="flex-1">
                    <div className="flex flex-wrap items-baseline gap-2">
                      <span className="text-base sm:text-lg font-medium">{variation.name}</span>
                      <span className="text-sm text-gray-500">
                        ({formatDuration(variation)})

                      </span>
                    </div>
                    {variation.description && (
                      <p className="text-sm text-gray-600 mt-1 leading-relaxed">{variation.description}</p>
                    )}
                  </div>
                  <div className="text-right mt-2 sm:mt-0">
                    <div className="text-lg sm:text-xl font-medium text-primary">£{variation.price}</div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="px-4 sm:px-6 py-4 border-t bg-gray-50">
          <button
            onClick={onClose}
            className="w-full py-3 px-4 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 rounded-lg transition-colors text-base font-medium touch-manipulation"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VariationModal;






// [
//   {
//     "bookingLength": {
//       "hours": 1,
//       "minutes": 15
//     },
//     "bookingLengthHours": "1",
//     "bookingLengthMinutes": "15",
//     "description": "fdsa",
//     "name": "Test 1",
//     "price": 1
//   },
//   {
//     "bookingLength": {
//       "hours": 2,
//       "minutes": 16
//     },
//     "bookingLengthHours": "2",
//     "bookingLengthMinutes": "16",
//     "description": "",
//     "name": "Test 2",
//     "price": 2
//   }
// ]
